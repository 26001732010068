import React from 'react';
import { BEAR, Circus, feel, giant, MAN, orange, pig, Quest, RING, Tall, UMBRELLA, vine} from './clips';
// import Video from "./video/demo.m4v";
import ReactPlayer from 'react-player'
import './App.css';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'


class BuildingBlock extends React.Component {
  constructor(props) {
    super(props);
    this.handleTestPage = this.handleTestPage.bind(this);
    this.handleDemoPage = this.handleDemoPage.bind(this);
    this.handleHomePage = this.handleHomePage.bind(this);
    this.onGender = this.onGender.bind(this);
    this.onGrade = this.onGrade.bind(this);
    this.state = {
      isHome: true,
      isDemo: false,
      isTest: false,
      Password: "",
      PostData: [],
      Name: "",
      id: "",
      icon: "",
      friends: [],
      personalDetails: 0,
      authError: false,
      grade: null,
      gender: null
    };
  }

  // componentDidMount() {
  // }  

  onGender(e) {
    this.setState({
      gender: e.target.id.toString(),
    });
  }

  onGrade(e) {
    this.setState({
      grade: e.target.id.toString(),
    });
  }

  handleDemoPage() {
    this.setState({
      isHome: false,
      isDemo: true,
      isTest: false
    });
  }

  handleTestPage() {
    this.setState({
      isHome: false,
      isDemo: false,
      isTest: true
    });
  }

  handleHomePage() {
    this.setState({
      isHome: true,
      isDemo: false,
      isTest: false,
      grade: null,
      gender: null
    });
  }

  render() {
    let view = null;
    if (this.state.isHome) {
      view = <Home
        onDemoClick={this.handleDemoPage}
        onTestClick={this.handleTestPage}
        grade={this.state.grade}
        gender={this.state.gender}
        onGender={this.onGender}
        onGrade={this.onGrade}
      />;
    } 
    else if (this.state.isDemo) {
      view = <Demo
        onHomeClick={this.handleHomePage}
        onTestClick={this.handleTestPage}
      />;
    }
    else{
      view = <Test
        onHomeClick={this.handleHomePage}
        onDemoClick={this.handleDemoPage}
      />;
    }
    return (
      <div className="container">
        <div className="Outer">
          {view}
        </div>
      </div>
      
    );
  }
} 


class Home extends React.Component {
  constructor(props) {
     super(props);
     this.state = {

    };
  }

  
  render() {
    let gradeButtons = []
    let genderButtons = []

    for(var i =0; i<6; i++){
      if(this.props.grade == (i+1).toString()){
        gradeButtons.push(<button id={i+1} className="gradeButtonSelected" onClick={this.props.onGrade}>{i+1}</button>)
      }
      else{
        gradeButtons.push(<button id={i+1} className="gradeButton" onClick={this.props.onGrade}>{i+1}</button>)
      }
    }
    
    let gender = ["Male", "Female"]
    for(var i=0; i<2; i++){
      if(this.props.gender == gender[i].toString()){
        genderButtons.push(<button id={gender[i]} className="gradeButtonSelected" onClick={this.props.onGender}>{gender[i]}</button>)
      }
      else{
        genderButtons.push(<button id={gender[i]} className="gradeButton" onClick={this.props.onGender}>{gender[i]}</button>)
      }
    }

    return (
    <div className="login">
        <h3 className="mainTitle">Building Blocks!</h3><br/>
        <img className="logo" src="/bb-logo.PNG" width="20%" height="15%" />
        <div className="centerBlock">
          <div className="buttonBlock">
            <h3 className="title">My grade is: &nbsp;&nbsp;</h3>
              {gradeButtons}
          </div>
          
          <div className="buttonBlock">
            <h3 className="title">I am a: &nbsp;&nbsp;</h3>
              {genderButtons}
          </div>
          
        </div>
        <div className="buttonBlock">
          <button className="myButton" onClick={this.props.onDemoClick}>Demo</button>
          {(this.props.grade !== null && this.props.gender !== null) ? (
            <button className="myButton" onClick={this.props.onTestClick}>Start Test</button>
          ):(
            <button className="myButtonDisabled" onClick={this.props.onTestClick} disabled>Start Test</button>
          )
          }
          
        </div>
		</div>
    );
  }
}

class Demo extends React.Component {
  constructor(props) {
     super(props);
      //bind this to functions
  }


  render() {
    
    return (
    <div className="Outer">
        <center><a className="title">Demo</a></center>
        <ReactPlayer
            className='react-player fixed-bottom'
            url= 'demo.M4V'
            width='100%'
            height='100%'
            controls = {true}
            />
        <div className="buttonBlock">
          <button className="myButton" onClick={this.props.onHomeClick}>Home Page</button>
          <button className="myButton" onClick={this.props.onTestClick}>Start Test</button>
        </div>
		</div>
    );
  }
}

class Test extends React.Component {
  constructor(props) {
     super(props);
      //bind this to functions
      this.handleNextQuestion = this.handleNextQuestion.bind(this);
      this.timeOut = this.timeOut.bind(this);
      this.state = {
        currentID: -1,
        qData: [BEAR, Circus, feel, giant, MAN, orange, pig, Quest, RING, Tall, UMBRELLA, vine],
        qAnswer: ["B", "C", "F", "G", "M", "O", "P", "Q", "R", "T", "U", "V"],
        userAnswers: [],
        currentClip: "",
        finalScore: 0,
        isScorePage: false
      };
  }

  componentWillMount(){
    this.handleNextQuestion();
  }

  timeOut() {
    this.state.currentID = this.state.qData.length-1
    this.handleNextQuestion();
  }

  handleNextQuestion(e) {
    if(e !== undefined){
      e.preventDefault(e);
      this.state.userAnswers[this.state.currentID] = e.target.id.toString()
      if(e.target.id == this.state.qAnswer[this.state.currentID]){
        this.state.finalScore+=1
      }
    }
    
    this.state.currentID+=1;

    if(this.state.qData.length == this.state.currentID){
      var finScore = this.state.finalScore;
      this.setState({
        isScorePage: true,
        finalScore: finScore
      })
    }
    else{
      var clip=this.state.qData[this.state.currentID];
      this.setState({
        currentClip: clip
      });
    }
    
   //play the sound
  }


  render() {
    let view = ""
    if(!this.state.isScorePage){
      view = <Question
      clip={this.state.currentClip}
      nextQuestion={this.handleNextQuestion}
      qNum={this.state.currentID}
      qNumLength={this.state.qData.length}
      timeOut={this.timeOut}
      />;
    }
    else{
      view = <Score
          finalScore={this.state.finalScore}
          totalQ={this.state.qData.length}
          onHomeClick={this.props.onHomeClick}
          onDemoClick={this.props.onDemoClick}
          qData={this.state.qData}
          qAnswer={this.state.qAnswer}
          userAnswers={this.state.userAnswers}
        />
    }
    
    
    return (
    <div className="Outer">
        {view}
        
		</div>
    );
  }
}

class Score extends React.Component {
  constructor(props) {
     super(props);
      //bind this to functions
  }


  render() {
    let ScoreButton = <ScoreButtonSet
      qData={this.props.qData}
      qAnswers={this.props.qAnswer}
      userAnswers={this.props.userAnswers}
    />

    return (
    <div className="login">
        <h1 className="title">Final Score: {this.props.finalScore} / {this.props.totalQ}</h1> <br/>
        <div>
          {ScoreButton}
        </div>
        <div className="buttonBlock">
          <button className="myButton" onClick={this.props.onHomeClick}>Home Page</button>
          <button className="myButton" onClick={this.props.onDemoClick}>Demo</button>
        </div>
		</div>
    );
  }
}

class ScoreButtonSet extends React.Component {
  constructor(props) {
      super(props);
      //bind this to functions
      this.playSound = this.playSound.bind(this);
      this.playNow = this.playNow.bind(this);
      this.state = {
        buttons: ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
        arrowVisibility: null,
        audio: null
      };
  }


  playNow(clip){
    if(this.state.audio !== null) {
      this.state.audio.pause();
    }
    this.state.audio= new Audio(clip)
    var playPromise = this.state.audio.play();
    if (playPromise !== undefined) {
      playPromise
        .then(_ => {
          console.log("audio played auto");
        })
        .catch(error => {
          console.log("playback prevented"+error);
        });
    }
  }


  playSound(e){
    var clip = e.target.name;
    if(clip == "") return;
    if (e!==undefined){
      e.preventDefault(e);
    }
    this.setState({
      arrowVisibility: e.target.id
    })

    this.playNow(clip)

  }


  render() {
    let rows = [];
    let className = "";
    let visible = "";

    
    this.state.buttons.map((buttonLabel) => {
      var index = this.props.qAnswers.findIndex(obj => obj === buttonLabel)
      // this.state
      if(index == -1){
        className = "alphaButton";
        rows.push(
          <ScoreButton
              label={buttonLabel}
              clip=""
              colorClass={className}
              arrowVisibility="hideArr"
              playSound={this.playSound}
            />
        );
      }
      else{
        if(this.props.userAnswers[index] == this.props.qAnswers[index]){
          className = "greenButton";
        }
        else {
          className = "redButton";
        }
        if(buttonLabel == this.state.arrowVisibility){
          visible = "showArr"
        }
        else{
          visible = "hideArr"
        }
  
        rows.push(
          <ScoreButton
              label={buttonLabel}
              clip={this.props.qData[index]}
              colorClass={className}
              arrowVisibility={visible}
              playSound={this.playSound}
            />
        );
      }
      
    });

    return (
    <span className="">
      <div className="buttonBlock">
        {rows}
      </div>
      
		</span>
    );
  }
}

class ScoreButton extends React.Component {
  constructor(props) {
      super(props);
      //bind this to functions
  }

  


  render() {
    let arrowColoring = {"alphaButton":"black" ,"greenButton":"#008000", "redButton":"red"}
    let arrowText = {"alphaButton":"" ,"greenButton":"O", "redButton":"X"}
    

    return (
      <div className="buttonItem">
          <div className={this.props.arrowVisibility}>
              <svg id={this.props.label+"2"} className="sv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <defs >
                  <marker id={this.props.label+"arrowhead"} markerWidth="2" markerHeight="4" className={this.props.colorClass+"poly"}
                    refX="1" refY="2" orient="90deg">
                    <polygon fill={arrowColoring[this.props.colorClass]} points="0 0, 2 2, 0 4" />
        
                  </marker>
                </defs>
                <line x1="50" y1="10" x2="50" y2="84" stroke={arrowColoring[this.props.colorClass]} fill={arrowColoring[this.props.colorClass]}
                stroke-width="16" marker-end={"url(#"+this.props.label+"arrowhead)"} />
                <text x="25" y="60" font-size="14px" text-anchor="middle" fill="white" stroke="white" stroke-width="0.2" transform="rotate(90,45,60)">{arrowText[this.props.colorClass]}</text>
                
                </svg>
          </div>
          <button className={this.props.colorClass} name={this.props.clip} id={this.props.label} onClick={this.props.playSound}>{this.props.label}</button>
      </div>    
    );
  }
}



class CountDownBar extends React.Component {
  constructor(props) {
     super(props);
      //bind this to functions
      this.progress = this.progress.bind(this);
      this.bar = React.createRef()
      this.state = {
        width: 0,
        time: 0 
      };
  }

  componentDidMount(){
    console.log(this.bar.current.offsetWidth)
    this.progress(this.props.duration, this.props.duration, document.getElementById("progressBar"));
  }

  progress(timeleft, timetotal, $element) {
    var progressBarWidth = timeleft * this.bar.current.offsetWidth / timetotal;
    var sec = (timeleft%60).toString().length == 1? "0"+(timeleft%60).toString() : timeleft%60
    var remTime = Math.floor(timeleft/60) + ":" + sec;
    // $element.find('div').animate({ width: progressBarWidth }, 500).html(Math.floor(timeleft/60) + ":"+ timeleft%60);
    this.setState({
      time: remTime,
      width: progressBarWidth
    });
    if(timeleft > 0) {var vm = this;
        setTimeout(function() {
            vm.progress(timeleft - 1, timetotal, $element);
        }, 1000);
    }
    else{
      this.props.onComplete();
    }
  };

  render() {
    
    return (
        <div ref={this.bar} id="progressBar">
            <div class="bar" style={{width: this.state.width}}>{this.state.time}</div>
        </div>
    );
  }
}


class Question extends React.Component {
  constructor(props) {
     super(props);
      //bind this to functions
      this.playSound = this.playSound.bind(this);
      this.nextQuestion = this.nextQuestion.bind(this);
      this.timeOut = this.timeOut.bind(this);
      
      this.state = {
        running: false, //prevent setTimeout from creating multiple requests
        audio: null
      };
  }


  playNow(){
    if(this.state.audio !== null) {
      this.state.audio.pause();
    }
    this.state.audio = new Audio(this.props.clip)
    var playPromise = this.state.audio.play();
    if (playPromise !== undefined) {
      playPromise
        .then(_ => {
          console.log("audio play supported by browser");
          var loops = 3;       
          var count = 0;
          var vm = this;
          this.state.audio.onended = function() { //since first played, the others would be supported in the browser too
            if(count < loops-1){
              count++;
              vm.state.audio.play();
           }
          };
        })
        .catch(error => {
          console.log("playback prevented by the browser: "+error);
        });
    }

    this.state.running = false;
  }

  playSound(e){
    if (e!==undefined){
      e.preventDefault(e);
    }
    if(!this.state.running){
      this.state.running = true;
      this.playNow()
    }
    
  }


  nextQuestion(e) {
    if(e !== undefined){
      e.preventDefault(e);
    }
    if(this.state.audio !== null) {
      this.state.audio.pause();
    }
    this.props.nextQuestion(e)
  }

  timeOut(e) {
    if(this.state.audio !== null) {
      this.state.audio.pause();
    }
    this.props.timeOut(e)
  }


  

  render() {
    
    // this.progress(600, 600, $('#progressBar'));
    

    let scale1 = "";
    let scale2 = ""
    for(var i=1; i<this.props.qNumLength+1; i++){
      if(i<this.props.qNum+1){
        scale1+=i+" ";
      }
      else if(i>this.props.qNum+1){
        scale2+=" "+i
      }
    }


    let buttons = <ButtonSet
        nextQuestion={this.nextQuestion}
      />;
    return (
    <span className="question">
        <div className="title buttonBlock">
          <div className="buttonAlignment">
            <img className="" src="/bb-logo.PNG" width="5%" height="3.5%" />
            <button className="playAgain" onClick={this.playSound}>Word {this.props.qNum+1}</button>
            </div>
            
            <CountDownBar 
              duration={540}
              onComplete={this.timeOut}
            />
          
        </div>
        {buttons}
        <br/>
          <div className="scale">{scale1}<a className="scaleHighlight">{this.props.qNum+1}</a>{scale2}</div>
        
		</span>
    );
  }
}

class ButtonSet extends React.Component {
  constructor(props) {
      super(props);
      //bind this to functions
      this.confirmation = this.confirmation.bind(this);
      this.nextQuestion = this.nextQuestion.bind(this);
      this.state = {
        buttons: ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z', '?'],
        selectedButton: ""
      };
  }

  nextQuestion(e){
    e.preventDefault(e)
    this.setState({
      selectedButton: ""
    });
    this.props.nextQuestion(e)
  }

  confirmation(e){
    e.preventDefault(e)
    this.setState({
      selectedButton: e.target.id
    });

  }


  render() {
    let rows = [];
    let className = "";
    let disabled = "";
    let buttonClass = ""
    

    this.state.buttons.map((buttonLabel) => {
        if(buttonLabel == this.state.selectedButton){
          className = "showArr";
          buttonClass = "redButton"
          disabled = false;
        }
        else if(this.state.selectedButton === ""){
          className = "hideArr";
          buttonClass = "alphaButton"
          disabled = false;
        }
        else{
          className = "hideArr";
          buttonClass = "alphaButton"
          disabled = true;
        }
        rows.push(
          <Button
            label={buttonLabel}
            nextQuestion={this.nextQuestion}
            confirmation={this.confirmation}
            className={className}
            disabled={disabled}
            buttonClass={buttonClass}
          />
        );
    });

    return (
    <span className="">
      <div className="buttonBlock">
        {rows}
      </div>
      
		</span>
    );
  }
}


class Button extends React.Component {
  constructor(props) {
      super(props);
      //bind this to functions
  }


  render() {

    return (
      <div className="buttonItem">
          <div className={this.props.className}>
              <svg id={this.props.label+"1"} className="sv" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <defs>
                  <marker id="arrowhead" markerWidth="2" markerHeight="4" 
                  refX="1" refY="2" orient="90deg" >
                    <polygon points="0 0, 2 2, 0 4" fill="#FF00FF"/>
                  </marker>
                </defs>
                <line x1="50" y1="10" x2="50" y2="84" stroke="#FF00FF" fill="#FF00FF"
                stroke-width="16" marker-end="url(#arrowhead)" />
                <text x="25" y="60" font-size="14px" text-anchor="middle" fill="white" stroke="white" stroke-width="0.3" transform="rotate(90,45,60)">選んだ</text>
                
                <circle cx="19" cy="25" r="19" stroke="black" stroke-width="1" fill="red" />
                <circle cx="81" cy="25" r="19" stroke="black" stroke-width="1" fill="blue" />
                <text x="19" y="25" cursor= "pointer" font-size="14px" id="" onClick={this.props.confirmation} text-anchor="middle" fill="white"stroke="white" stroke-width="0.3" dy=".3em">No</text>
                <text x="81" y="25" cursor= "pointer" font-size="14px" id={this.props.label} onClick={this.props.nextQuestion} text-anchor="middle" fill="white"stroke="white" stroke-width="0.3" dy=".3em">Yes</text>
              </svg>
          </div>
          {this.props.label == "?" ? (
            <button className="dkButton" id={this.props.label} onClick={this.props.confirmation} disabled={this.props.disabled}>{this.props.label}</button>
          ):(
            <button className={this.props.buttonClass} id={this.props.label} onClick={this.props.confirmation} disabled={this.props.disabled}>{this.props.label}</button>
          )}
          
      </div>    
    );
  }
}

export default BuildingBlock;
